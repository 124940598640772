import React, { useState } from "react";

import { useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import CustomiseTheme from "./components/customise";
import ScrollTop from "./components/scroll-to-top";
import { NotificationProvider } from "../NotificationContext";

const { Content } = Layout;

export default function VerticalLayout(props) {
  const { children } = props;

  const [visible, setVisible] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  return (
    <NotificationProvider>
      <Layout className="hp-app-layout">
        <Sidebar visible={visible} setVisible={setVisible} />

        <Layout className="hp-bg-color-dark-90">
          <MenuHeader setVisible={setVisible} />

          <Content>{children}</Content>

          {/* <MenuFooter /> */}
        </Layout>

        {/* <CustomiseTheme /> */}

        {/* <ScrollTop /> */}
      </Layout>
    </NotificationProvider>
  );
}
