import React, { createContext, useContext, useState, useEffect } from "react";
import { API_URL } from "./api/config";
import { useHistory } from "react-router-dom";
import Error500 from "./view/pages/error/500";
import { get } from "./utils/service";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const history = useHistory();

  const [dataNotification, setDataNotification] = useState(null);

  const getNotification = async () => {
    const res = await get(API_URL.notification + "?status=0");
    if (res?.status === 200) {
      setDataNotification(res?.data);
    }
  };

  const checkSession = () => {
    const storage = localStorage.getItem("_biskon_session");
    if (storage) {
      getNotification();
    }
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      checkSession();
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <NotificationContext.Provider value={dataNotification}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useGetNotification = () => {
  return useContext(NotificationContext);
};
