import React from "react";

import { useSelector } from "react-redux";

import { Button, Badge, Row, Col, Dropdown, Divider, Avatar } from "antd";
import { Notification, Paper } from "react-iconly";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function HeaderNotifications({ notifData }) {
  const direction = useSelector((state) => state.customise.direction);
  const history = useHistory();

  const notificationMenu = (
    <div
      className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-pt-24 hp-pb-18 hp-px-18 hp-mt-18"
      style={{ width: 288 }}
    >
      <Row align="middle" justify="space-between" className="hp-mb-18">
        <Col className="h5 hp-text-color-black-100 hp-text-color-dark-10 hp-text-color-dark-0 hp-mr-64">
          Notifikasi
        </Col>

        <Col className="hp-bg-color-primary-1 hp-border-radius-full hp-badge-text hp-text-color-black-0 hp-py-4 hp-px-6 hp-ml-24">
          {notifData?.data?.count}
        </Col>
      </Row>

      <Divider className="hp-my-4" />

      <div
        className="hp-overflow-y-auto hp-px-10"
        style={{ maxHeight: 300, marginRight: -10, marginLeft: -10 }}
      >
        {notifData?.data?.data?.slice(0, 4).map((item, index) => (
          <>
            <Link to={"/eksepsi/detail?q=" + item.uuid}>
              <Row
                align="middle"
                className="hp-cursor-pointer hp-border-radius hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-py-8 hp-px-10"
                style={{ marginLeft: -10, marginRight: -10 }}
              >
                <Col className="hp-mr-8">
                  <Avatar
                    size={38}
                    icon={
                      <Paper size={16} className="hp-text-color-orange-1" />
                    }
                    className="hp-d-flex-center-full hp-bg-orange-4"
                  />
                </Col>

                <Col>
                  <span className="hp-d-block hp-w-100 hp-mb-4 hp-font-weight-500 hp-p1-body">
                    {item.pengajuan_kode}
                  </span>

                  <span className="hp-d-block hp-badge-text hp-font-weight-400 hp-text-color-black-60 hp-text-color-dark-40">
                    Terdapat Data Eksepsi Pending
                  </span>
                </Col>
              </Row>
            </Link>
            {index < 4 && <Divider className="hp-my-4" />}{" "}
          </>
        ))}

        <Button
          type="text"
          block
          ghost
          className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-fill-primary-1 hp-fill-dark-primary-2 hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-mt-4"
          onClick={() => history.push("/notification")}
        >
          Lihat Semua Notif
        </Button>
      </div>
    </div>
  );

  return (
    <Col className="hp-d-flex-center hp-mr-sm-12 hp-mr-16">
      <Button
        type="text"
        icon={
          <Dropdown overlay={notificationMenu} placement="bottomRight">
            <div className="hp-position-relative">
              <div
                className="hp-position-absolute"
                style={
                  direction == "rtl"
                    ? { left: -5, top: -5 }
                    : { right: -5, top: -5 }
                }
              >
                <Badge dot status="processing" />
              </div>

              <Notification set="curved" className="hp-text-color-black-60" />
            </div>
          </Dropdown>
        }
      />
    </Col>
  );
}
